import React from "react"
import Hero from "../components/hero-404"
import "../assets/index.css"
import Layout from "../layouts/layout-contactus"


export default () => (
    <Layout title="404 | Ladd Partners"> 
        <Hero/>
    </Layout>
)
